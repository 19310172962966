<template>
  <company-templates></company-templates>
</template>

<script>
import CompanyTemplates from "@/components/company/templates/CompanyTemplates";
export default {
  name: "Templates",
  components: {
    CompanyTemplates
  }
};
</script>
