<template>
  <div class="flex flex-col bg-white">
    <div
      class="flex items-center pb-1 mobile:justify-between mobile:flex-wrap w-full"
    >
      <div
        class="flex mobile:mb-3 mobile:justify-between mobile:w-full flex-col w-full"
      >
        <h2 class="mr-2 mb-4">Mallid</h2>
        <div class="tab-tray mb-4">
          <div
            class="tab-tray-item w-1/4 rounded-l-xl"
            :class="subPage === 1 ? 'tab-tray-item-active' : ''"
            @click="subPage = 1"
          >
            <div class="tab-tray-icon">
              <img src="/icons/templates/services.svg" />
            </div>
            <div class="tab-tray-label">
              <span class="font-medium">
                Teenused
              </span>
            </div>
          </div>
          <div
            class="tab-tray-item w-1/4"
            @click="subPage = 2"
            :class="subPage === 2 ? 'tab-tray-item-active' : ''"
          >
            <div class="tab-tray-icon">
              <img src="/icons/templates/materials.svg" />
            </div>
            <div class="tab-tray-label">
              <span class="font-medium">
                Materjalid
              </span>
            </div>
          </div>
          <div
            class="tab-tray-item w-1/4"
            :class="subPage === 3 ? 'tab-tray-item-active' : ''"
            @click="subPage = 3"
          >
            <div class="tab-tray-icon">
              <img src="/icons/templates/descriptions.svg" class="h-8 w-8" />
            </div>
            <div class="tab-tray-label">
              <span class="font-medium">
                Kirjeldused
              </span>
            </div>
          </div>
          <div
            class="tab-tray-item w-1/4 rounded-r-xl"
            :class="subPage === 4 ? 'tab-tray-item-active' : ''"
            @click="subPage = 4"
          >
            <div class="tab-tray-icon">
              <img src="/bc21/project.svg" />
            </div>
            <div class="tab-tray-label">
              <span class="font-medium">
                Meilid
              </span>
            </div>
          </div>
        </div>
        <div class="flex h-full py-2">
          <service-templates
            v-if="subPage === 1 && templatesLoaded"
            :templates="templates"
            @templateRequest="loadServiceTemplates"
          >
          </service-templates>
          <material-templates
            v-if="subPage === 2 && templatesLoaded"
            :templates="templates"
            @templateRequest="loadServiceTemplates"
          >
          </material-templates>
          <detail-templates v-if="subPage === 3"></detail-templates>
          <email-templates v-if="subPage === 4"></email-templates>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceTemplates from "@/components/company/templates/ServiceTemplates";
import MaterialTemplates from "@/components/company/templates/MaterialTemplates";
import DetailTemplates from "@/components/company/templates/DetailTemplates";
import EmailTemplates from "@/components/company/templates/EmailTemplates";
import RequestHandler from "@/assets/mixins/RequestHandler";
export default {
  name: "CompanyTemplates",
  components: {
    EmailTemplates,
    DetailTemplates,
    ServiceTemplates,
    MaterialTemplates
  },
  data() {
    return {
      subPage: 1,
      templates: null,
      templatesLoaded: false,
      subPageHovered: null
    };
  },
  mixins: [RequestHandler],
  methods: {
    loadServiceTemplates() {
      this.templatesLoaded = false;
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/items/",
        "get",
        true
      ).then(res => {
        this.templates = res.data;
        this.templatesLoaded = true;
      });
    }
  },
  mounted() {
    this.loadServiceTemplates();
  }
};
</script>

<style scoped lang="scss">
.tab-menu {
  @apply flex flex-row justify-between w-full rounded-xl bg-offwhite shadow-sm px-2;
  .tab-menu-item {
    @apply flex-auto cursor-pointer flex items-center px-2 w-1/4 py-1 border-b-2 duration-100;
    .tab-menu-item-icon {
      @apply rounded-full p-1;
      img {
        @apply h-6 w-6;
      }
    }
    .tab-menu-item-text {
      @apply flex justify-center w-auto flex-grow;
    }
    &:hover {
      @apply bg-offerwhite bg-opacity-50;
    }
  }
}
</style>
