<template>
  <div class="flex flex-col bg-white rounded-gwdhalf w-full">
    <span class="flex break-words items-center mb-4"
      ><span
        class="typcn typcn-info-large border-green border-2 rounded-full text-xl p-1 leading-none text-green mr-2"
      ></span
      >Lisa siia tekst, mida soovid tihti arvete ja pakkumiste kirjelduses
      kasutada.</span
    >
    <div class="flex flex-col py-3" v-if="detailsLoaded">
      <div class="flex w-full items-center mb-4">
        <div class="flex w-11/12 mobile:w-full">
          <h3>Pakkumised</h3>
        </div>
        <div class="flex w-1/12 justify-center mobile:w-6/12 mobile:hidden">
          <span class="flex">Vaikimisi valik?</span>
        </div>
      </div>

      <detail-template
        v-for="detail in details.filter(x => x.type === 1)"
        :key="detail.id"
        :detail="detail"
        @newDefault="setNewDefaultTemplate"
        @editDetails="editDetailTemplate"
        @deleteDetails="deleteDetails"
        class="flex flex-row w-full"
      >
      </detail-template>

      <div
        v-if="newDetailsForm && newDetailsType === 1"
        class="w-full flex-col flex mt-3"
      >
        <h3 class="mb-2">Lisa kirjeldus</h3>
        <form-label
          :required="true"
          title="Nimetus"
          :valid="!$v.newDetails.description.$invalid"
          :error="$v.newDetails.description.$error"
          :error-text="
            !$v.newDetails.description.maxLength
              ? 'Nimetus võib olla kuni 64 tähemärki pikk'
              : 'Kohustuslik'
          "
        ></form-label>
        <input class="mb-4" type="text" v-model="newDetails.description" />
        <form-label
          :required="true"
          title="Sisu"
          :valid="!$v.newDetails.details.$invalid"
          :error="$v.newDetails.details.$error"
          :error-text="
            !$v.newDetails.details.maxLength
              ? 'Sisu võib olla kuni 1024 tähemärki pikk'
              : 'Kohustuslik'
          "
        ></form-label>
        <textarea type="text" v-model="newDetails.details" rows="3" />
      </div>

      <div class="flex py-3 mb-6">
        <button
          v-if="!newDetailsForm"
          @click="addNewDetails(1)"
          class="alt-button-green"
        >
          <span class="typcn typcn-plus icon"></span>
          <span class="label">Lisa kirjeldus</span>
        </button>
        <button
          v-if="newDetailsForm && newDetailsType === 1"
          @click="saveDetails(1)"
          class="new-button-green mr-2"
        >
          <span class="typcn typcn-tick icon"></span>
          <span class="label">Salvesta</span>
        </button>
        <button
          @click="closeNewDetailsForm"
          v-if="newDetailsForm && newDetailsType === 1"
          class="new-button-danger"
        >
          <span class="typcn typcn-times icon"></span>
          <span class="label">Tühista</span>
        </button>
      </div>

      <div class="flex w-full items-center mb-4">
        <div class="flex w-11/12 mobile:w-6/12">
          <h3>Arved</h3>
        </div>
        <div class="flex w-1/12 justify-center mobile:w-6/12">
          <span class="flex">Vaikimisi valik?</span>
        </div>
      </div>

      <detail-template
        v-for="detail in details.filter(x => x.type === 2)"
        :key="detail.id"
        :detail="detail"
        @newDefault="setNewDefaultTemplate"
        @editDetails="editDetailTemplate"
        @deleteDetails="deleteDetails"
        class="flex flex-row mb-1 w-full"
      >
      </detail-template>

      <div
        v-if="newDetailsForm && newDetailsType === 2"
        class="w-full flex-col flex mt-2"
      >
        <h3>Lisa kirjeldus</h3>
        <form-label
          :required="true"
          title="Nimetus"
          :valid="!$v.newDetails.description.$invalid"
          :error="$v.newDetails.description.$error"
          :error-text="
            !$v.newDetails.description.maxLength
              ? 'Nimetus võib olla kuni 64 tähemärki pikk'
              : 'Kohustuslik'
          "
        ></form-label>
        <input
          class="my-2"
          type="text"
          placeholder="Nimetus"
          v-model="newDetails.description"
        />
        <form-label
          :required="true"
          title="Nimetus"
          :valid="!$v.newDetails.details.$invalid"
          :error="$v.newDetails.details.$error"
          :error-text="
            !$v.newDetails.details.maxLength
              ? 'Sisu võib olla kuni 1024 tähemärki pikk'
              : 'Kohustuslik'
          "
        ></form-label>
        <textarea
          class="mt-2"
          type="text"
          placeholder="Sisu"
          v-model="newDetails.details"
          rows="3"
        />
      </div>

      <div class="flex py-3">
        <button
          v-if="!newDetailsForm"
          @click="addNewDetails(2)"
          class="alt-button-green"
        >
          <span class="typcn typcn-plus icon"></span>
          <span class="label">Lisa kirjeldus</span>
        </button>
        <button
          v-if="newDetailsForm && newDetailsType === 2"
          @click="saveDetails(2)"
          class="new-button-green mr-2"
        >
          <span class="typcn typcn-tick icon"></span>
          <span class="label">Salvesta</span>
        </button>
        <button
          @click="closeNewDetailsForm"
          v-if="newDetailsForm && newDetailsType === 2"
          class="new-button-danger"
        >
          <span class="typcn typcn-times icon"></span>
          <span class="label">Tühista</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import DetailTemplate from "@/components/company/templates/DetailTemplate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "DetailTemplates",
  components: { DetailTemplate },
  data() {
    return {
      detailsLoaded: false,
      details: [],
      newDetailsForm: false,
      newDetailsType: 1,
      newDetails: {
        description: "",
        details: "",
        type: 1
      }
    };
  },
  mixins: [RequestHandler],
  methods: {
    addNewDetails(type) {
      this.newDetailsForm = true;
      this.newDetailsType = type;
    },
    loadDetailTemplates() {
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/details/",
        "get",
        true
      ).then(res => {
        this.details = res.data;
        this.detailsLoaded = true;
      });
    },
    closeNewDetailsForm() {
      this.newDetails = {
        description: "",
        details: ""
      };
      this.newDetailsForm = false;
    },
    editDetailTemplate(e) {
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/details/edit/",
        "patch",
        true,
        e
      ).then(res => {
        if (res) {
          this.detailsLoaded = false;
          this.loadDetailTemplates();
        }
      });
    },
    setNewDefaultTemplate(e, set) {
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/details/edit/",
        "put",
        true,
        { id: e.id, default: set }
      ).then(res => {
        if (res) {
          this.detailsLoaded = false;
          this.loadDetailTemplates();
        }
      });
    },
    saveDetails(type) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.newDetails["type"] = type;
        this.apiRequest(
          "projects/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/templates/details/new/",
          "post",
          true,
          this.newDetails
        ).then(res => {
          if (res) {
            this.newDetailsForm = false;
            this.newDetails = {
              description: "",
              details: "",
              type: 1
            };
            this.detailsLoaded = false;
            this.loadDetailTemplates();
          }
        });
      }
    },
    deleteDetails(e) {
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/details/" +
          e.id +
          "/delete/",
        "delete",
        true
      ).then(res => {
        if (res) {
          this.detailsLoaded = false;
          this.loadDetailTemplates();
        }
      });
    }
  },
  mounted() {
    this.loadDetailTemplates();
  },
  watch: {
    "newDetails.description": {
      handler(val) {
        if (val.length > 64) {
          this.$v.$touch();
        }
      }
    },
    "newDetails.details": {
      handler(val) {
        if (val.length > 1024) {
          this.$v.$touch();
        }
      }
    }
  },
  validations: {
    newDetails: {
      description: { required, maxLength: maxLength(64) },
      details: { required, maxLength: maxLength(1024) }
    }
  }
};
</script>
