<template>
  <div class="w-full flex-col">
    <div
      class="flex flex-row w-full mobile:flex-col py-2 hover:bg-offwhite hover:bg-opacity-50"
      @click="expanded = !expanded"
      :class="expanded ? 'bg-offwhite bg-opacity-50' : ''"
    >
      <div
        class="flex flex-col w-6/12 items-center cursor-pointer mobile:w-full mobile:py-2 px-2"
      >
        <div class="p-2 flex flex-row items-center w-full justify-between mr-3">
          <div class="flex w-full">
            <span v-if="!editMode" class="font-semibold">
              {{ email.description }}</span
            >
            <input
              v-if="editMode"
              type="text"
              v-model="editables.description"
              class="w-full"
            />
          </div>
        </div>
      </div>

      <div
        class="flex flex-row w-5/12 justify-end ml-3 mobile:ml-0 mobile:w-full mobile:justify-around items-center mobile:flex-col"
      >
        <div class="mobile:w-full">
          <button
            v-if="!editMode"
            class="new-button-pending mobile:w-full mr-3 mobile:mr-0"
            @click.stop.prevent="editMode = true"
          >
            <span class="typcn typcn-edit icon"></span>
            <span class="label">Muuda</span>
          </button>
        </div>
        <div class="mobile:w-full mobile:mb-4">
          <button
            v-if="editMode"
            @click.stop.prevent="saveDetails"
            class="new-button-green mobile:w-full mr-3 mobile:mr-0"
          >
            <span class="typcn typcn-tick icon"></span>
            <span class="label">Salvesta</span>
          </button>
        </div>
        <div class="mobile:w-full mobile:mb-4">
          <button
            v-if="!editMode"
            @click.stop.prevent="confirmRemoveModalActive = true"
            class="new-button-danger mobile:w-full"
          >
            <span class="icon"
              ><img src="/bc21/trash.svg" class="h-6 filter-to-white"
            /></span>
            <span class="label">Kustuta</span>
          </button>
        </div>
        <div class="mobile:w-full">
          <button
            v-if="editMode"
            class="new-button-danger"
            @click.stop.prevent="editMode = false"
          >
            <span class="typcn typcn-times icon"></span>
            <span class="label">Tühista</span>
          </button>
        </div>
      </div>
      <div
        class="w-1/12 mobile:w-full flex justify-center mobile:justify-start items-center"
      >
        <h4 class="mobile:w-1/2 mobile:flex hidden">Vaikimisi valik</h4>
        <div
          class="flex mobile:w-1/2 justify-center w-full mobile:justify-end mobile:pr-2"
        >
          <base-checkbox
            :base-val="email.default"
            @valueChanged="setTemplateAsDefault($event)"
          ></base-checkbox>
        </div>
      </div>
    </div>
    <div v-if="expanded || editMode" class="w-full px-2 py-4">
      <div class="mb-2">
        <h4>Teema / meili pealkiri</h4>
        <span v-if="!editMode"> {{ email.title }}</span>
        <input
          v-if="editMode"
          type="text"
          v-model="editables.title"
          class="w-full"
        />
      </div>
      <div class="mb-2">
        <h4>Meili sisu</h4>
        <span v-if="!editMode"> {{ email.header }}</span>
        <textarea
          v-if="editMode"
          type="text"
          v-model="editables.header"
          rows="3"
          class="w-full"
        />
      </div>
      <div class="mb-2">
        <h4>Meili jalus</h4>
        <span v-if="!editMode"> {{ email.footer }}</span>
        <textarea
          v-if="editMode"
          type="text"
          v-model="editables.footer"
          rows="3"
          class="w-full"
        />
      </div>
    </div>
    <confirm-modal
      @closeModal="confirmRemoveModalActive = false"
      @confirm="deleteEmail"
      text="Olete kindel, et soovite valitud malli kustutada?"
      v-if="confirmRemoveModalActive"
    ></confirm-modal>
    <div class="h-1 bg-offwhite w-full rounded-full"></div>
  </div>
</template>

<script>
import ConfirmModal from "@/components/reusable/ConfirmModal";
export default {
  name: "EmailTemplate",
  components: { ConfirmModal },
  props: {
    email: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      editMode: false,
      editables: {
        id: "",
        description: "",
        title: "",
        header: "",
        footer: ""
      },
      expanded: false,
      confirmRemoveModalActive: false
    };
  },
  methods: {
    setTemplateAsDefault(set) {
      this.$emit("newDefault", this.email, set);
    },
    saveDetails() {
      this.$emit("editEmails", this.editables);
    },
    deleteEmail() {
      this.$emit("deleteEmail", this.email);
    }
  },
  mounted() {
    this.editables.id = this.email.id;
    this.editables.description = this.email.description;
    this.editables.title = this.email.title;
    this.editables.header = this.email.header;
    this.editables.footer = this.email.footer;
  }
};
</script>
