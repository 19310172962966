<template>
  <div class="flex flex-col bg-white rounded-gwdhalf w-full">
    <span class="flex break-words mb-4 items-center"
      ><span
        class="typcn typcn-info-large border-green border-2 rounded-full text-xl p-1 leading-none text-green mr-2"
      ></span
      >Lisa siia materjalid, mida sa soovid tihti arvetel ja pakkumistel
      kasutada. Nende lisamiseks arvele või pakkumisele alusta nimetuse
      kirjutamist ning see ilmub automaatselt.</span
    >
    <div v-if="error" class="mb-3 mt-1 flex error-box">
      <span>Märgitud teenuste nimed on liiga pikad!</span>
    </div>
    <div class="flex flex-col py-3">
      <div class="w-full grid grid-cols-12 service-ex-row-headers">
        <div
          class="flex col-span-4 mobile:col-span-5 justify-start"
          :class="
            editMode ? 'justify-start mobile:hidden pl-2' : 'justify-start'
          "
        >
          <h4>Kirjeldus</h4>
        </div>
        <div
          class="flex font-medium mobile:hidden"
          :class="editMode ? 'justify-start pl-2' : 'justify-start'"
        >
          <h4>Kogus</h4>
        </div>
        <div
          class="flex font-medium  mobile:hidden"
          :class="editMode ? 'justify-start pl-2' : 'justify-start'"
        >
          <h4>Ühik</h4>
        </div>
        <div
          class="flex font-medium  mobile:hidden"
          :class="editMode ? 'justify-start pl-2' : 'justify-start'"
        >
          <h4>Ühikuhind<span class="medium:hidden">(€)</span></h4>
        </div>
        <div
          class="flex font-medium  mobile:hidden"
          :class="editMode ? 'justify-start pl-2' : 'justify-start'"
        >
          <h4>Summa <span class="medium:hidden">(-km)</span></h4>
        </div>
        <div
          class="flex font-medium  mobile:hidden"
          :class="editMode ? 'justify-start pl-2' : 'justify-start'"
        >
          <h4>KM%</h4>
        </div>
        <div
          class="flex font-medium  mobile:hidden"
          :class="editMode ? 'justify-start pl-2' : 'justify-start'"
        >
          <h4>KM</h4>
        </div>
        <div
          class="flex font-medium mobile:col-span-5"
          :class="
            editMode
              ? 'justify-start mobile:hidden'
              : 'justify-start col-span-1'
          "
        >
          <h4>Summa</h4>
        </div>
      </div>
      <div
        class="w-full h-1 rounded-full mt-2 duration-100 bg-offwhite mb-1"
      ></div>
      <div
        class="flex flex-col"
        v-for="item in tempData.filter(x => x.item_type === 2)"
        :key="item.id"
      >
        <new-service-row
          :service="item"
          @removeService="handleRemoveService"
          @serviceUpdated="handleServiceUpdate"
          :index="genId(item)"
          :is-white="false"
          :disable-templates="true"
          v-if="editMode"
          class="mb-1"
          ref="oldItems"
        ></new-service-row>
        <div class="flex w-full h-1 bg-offwhite mb-1"></div>
      </div>
      <div class="flex flex-col" v-for="item in newServices" :key="item.index">
        <new-service-row
          :service="item"
          @removeService="removeService"
          :index="item.index"
          :is-white="false"
          :disable-templates="true"
          class="mb-1"
          ref="newItems"
        ></new-service-row>
        <div class="flex w-full h-1 bg-offwhite mb-1"></div>
      </div>
      <div class="flex mb-3 mt-1">
        <button @click="addService(2)" class="alt-button-green">
          <span class="typcn typcn-plus icon"></span>
          <span class="label">Lisa materjal</span>
        </button>
      </div>
      <div class="flex py-3">
        <button @click="updateServiceTemplates" class="new-button-green">
          <span class="typcn typcn-tick icon"></span>
          <span class="label">Salvesta</span>
        </button>
        <saving-loader :saving="saving" :saved="saved"></saving-loader>
      </div>
    </div>
  </div>
</template>
<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import NewServiceRow from "@/components/project/offer/services/NewServiceRow";
import SavingLoader from "@/components/reusable/SavingLoader";

export default {
  name: "MaterialTemplates",
  components: { NewServiceRow, SavingLoader },
  props: {
    templates: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      editMode: true,
      tempData: [],
      itemsLoaded: false,
      newServices: [],
      saving: false,
      saved: false,
      startIndex: 0,
      error: false
    };
  },
  mounted() {
    this.tempData = this.templates;
  },
  mixins: [RequestHandler],
  methods: {
    loadServiceTemplates() {
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/items/",
        "get",
        true
      ).then(res => {
        this.tempData = res.data;
        this.itemsLoaded = true;
      });
    },
    saveItems() {
      let data = [];
      for (let i = 0; i < this.newServices.length; i++) {
        let obj = {};
        obj.name = this.newServices[i].name;
        obj.amount = this.newServices[i].amount;
        obj.unit = this.newServices[i].unit;
        obj.price = this.newServices[i].price;
        obj.vat = this.newServices[i].vat;
        obj.item_type = this.newServices[i].item_type;
        data.push(obj);
      }
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/items/new/",
        "post",
        true,
        data
      ).then(res => {
        if (res) {
          this.saving = false;
          this.saved = true;
          let self = this;
          setTimeout(function() {
            self.saved = false;
          }, 5000);
          this.itemsLoaded = false;
          this.newServices = [];
          this.$emit("templateRequest");
        }
      });
    },
    updateServiceTemplates() {
      this.saved = false;
      this.saving = true;

      if (this.$refs.oldItems && this.$refs.oldItems.length > 0) {
        this.$refs.oldItems.forEach(x => {
          let error = x.checkMaxLength();
          if (error) {
            this.error = true;
          }
        });
      }
      if (this.$refs.newItems && this.$refs.newItems.length > 0) {
        this.$refs.newItems.forEach(x => {
          let error = x.checkMaxLength();
          if (error) {
            this.error = true;
          }
        });
      }
      if (!this.error) {
        this.apiRequest(
          "projects/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/templates/items/edit/",
          "patch",
          true,
          this.tempData
        ).then(res => {
          if (res) {
            if (this.newServices.length > 0) {
              this.saveItems();
            } else {
              this.saving = false;
              this.saved = true;
              let self = this;
              setTimeout(function() {
                self.saved = false;
              }, 5000);
              this.itemsLoaded = false;
              this.newServices = [];
              this.$emit("templateRequest");
            }
          }
        });
      } else {
        this.saving = false;
      }
    },
    handleServiceUpdate(e) {
      let itemToChange = this.tempData.findIndex(item => item.id === e.id);
      this.tempData[itemToChange] = e;
    },
    handleRemoveService(e) {
      this.tempData.splice(
        this.tempData.findIndex(x => x.id === e),
        1
      );
    },
    addService(itemType) {
      let data = {
        index: ++this.startIndex,
        name: "",
        price: 0,
        amount: 0,
        unit: 1,
        vat: 20,
        item_type: itemType
      };
      this.newServices.push(data);
    },
    removeService(e) {
      this.newServices.splice(
        this.newServices.findIndex(item => item.index === e),
        1
      );
    },
    genId(item) {
      const gennedId = Math.round(Math.random() * 100000);
      if (item) {
        this.templates.find(x => x.id === item.id).index = gennedId;
      }
      return gennedId;
    }
  },
  watch: {
    templates() {
      this.tempData = this.templates;
    }
  }
};
</script>
