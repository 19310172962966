<template>
  <div class="w-full flex-col cursor-pointer">
    <div
      class="flex flex-row items-center cursor-pointer mobile:flex-col py-2 hover:bg-offwhite hover:bg-opacity-50"
      @click="expanded = !expanded"
      :class="expanded ? 'bg-offwhite bg-opacity-50' : ''"
    >
      <div
        class="p-2 flex flex-row w-6/12 justify-between mr-3 mobile:mr-0 mobile:w-full items-center"
      >
        <div class="flex w-full">
          <span v-if="!editMode" class="font-semibold">
            {{ detail.description }}</span
          >
          <input
            v-if="editMode"
            type="text"
            v-model="editables.description"
            class="w-full mobile:mr-2"
          />
        </div>
      </div>
      <div
        class="flex flex-row w-5/12 pl-3 mobile:pl-0 justify-end mobile:w-full mobile:justify-around items-center mobile:flex-col"
      >
        <button
          v-if="!editMode"
          class="new-button-pending mr-3 mobile:w-full mobile:mr-0 mobile:mb-4"
          @click.stop.prevent="editMode = true"
        >
          <span class="typcn typcn-edit icon"></span>
          <span class="label">Muuda</span>
        </button>
        <button
          v-if="editMode"
          @click.stop.prevent="saveDetails"
          class="new-button-green mr-3 mobile:w-full mobile:mr-0 mobile:mb-4"
        >
          <span class="typcn typcn-tick icon"></span>
          <span class="label">Salvesta</span>
        </button>
        <button
          class="new-button-danger h-full mobile:w-full mobile:mb-4"
          @click.stop.prevent="confirmRemoveModalActive = true"
          v-if="!editMode"
        >
          <span class="icon"
            ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
          /></span>
          <span class="label">Kustuta</span>
        </button>
        <button
          v-if="editMode"
          class="new-button-danger hover:bg-dangerdark hover:shadow-dangeroutline mobile:w-full mobile:mb-4"
          @click.stop.prevent="editMode = false"
        >
          <span class="icon typcn typcn-times"></span>
          <span class="label">Tühista</span>
        </button>
      </div>
      <div class="w-1/12 mobile:w-full flex items-center justify-center">
        <div class="w-1/2 hidden mobile:flex">
          <h4 class="">Vaikimisi valik</h4>
        </div>
        <div
          class="flex w-full mobile:w-1/2 justify-center mobile:justify-end mobile:pr-2"
        >
          <base-checkbox
            @valueChanged="setTemplateAsDefault($event)"
            :base-val="detail.default"
          ></base-checkbox>
        </div>
      </div>
    </div>
    <div v-if="expanded || editMode" class="w-10/12 px-2 mobile:w-full py-4">
      <span v-if="!editMode"> {{ detail.details }}</span>
      <textarea
        v-if="editMode"
        v-model="editables.details"
        rows="3"
        class="w-9/12 mobile:w-full"
      />
    </div>
    <confirm-modal
      @closeModal="confirmRemoveModalActive = false"
      @confirm="deleteDetails"
      text="Olete kindel, et soovite valitud malli kustutada?"
      v-if="confirmRemoveModalActive"
    ></confirm-modal>
    <div class="h-1 bg-offwhite w-full rounded-full"></div>
  </div>
</template>

<script>
import ConfirmModal from "@/components/reusable/ConfirmModal";
export default {
  name: "DetailTemplate",
  components: { ConfirmModal },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      tempData: null,
      editMode: false,
      editables: {
        id: "",
        description: "",
        details: ""
      },
      expanded: false,
      confirmRemoveModalActive: false
    };
  },
  methods: {
    setTemplateAsDefault(set) {
      this.$emit("newDefault", this.detail, set);
    },
    saveDetails() {
      this.$emit("editDetails", this.editables);
    },
    deleteDetails() {
      this.$emit("deleteDetails", this.detail);
    }
  },
  mounted() {
    this.editables.id = this.detail.id;
    this.editables.description = this.detail.description;
    this.editables.details = this.detail.details;
  }
};
</script>
