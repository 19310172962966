var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col bg-white rounded-gwdhalf w-full"},[_vm._m(0),(_vm.detailsLoaded)?_c('div',{staticClass:"flex flex-col py-3"},[_vm._m(1),_vm._l((_vm.details.filter(function (x) { return x.type === 1; })),function(detail){return _c('detail-template',{key:detail.id,staticClass:"flex flex-row w-full",attrs:{"detail":detail},on:{"newDefault":_vm.setNewDefaultTemplate,"editDetails":_vm.editDetailTemplate,"deleteDetails":_vm.deleteDetails}})}),(_vm.newDetailsForm && _vm.newDetailsType === 1)?_c('div',{staticClass:"w-full flex-col flex mt-3"},[_c('h3',{staticClass:"mb-2"},[_vm._v("Lisa kirjeldus")]),_c('form-label',{attrs:{"required":true,"title":"Nimetus","valid":!_vm.$v.newDetails.description.$invalid,"error":_vm.$v.newDetails.description.$error,"error-text":!_vm.$v.newDetails.description.maxLength
            ? 'Nimetus võib olla kuni 64 tähemärki pikk'
            : 'Kohustuslik'}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newDetails.description),expression:"newDetails.description"}],staticClass:"mb-4",attrs:{"type":"text"},domProps:{"value":(_vm.newDetails.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newDetails, "description", $event.target.value)}}}),_c('form-label',{attrs:{"required":true,"title":"Sisu","valid":!_vm.$v.newDetails.details.$invalid,"error":_vm.$v.newDetails.details.$error,"error-text":!_vm.$v.newDetails.details.maxLength
            ? 'Sisu võib olla kuni 1024 tähemärki pikk'
            : 'Kohustuslik'}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newDetails.details),expression:"newDetails.details"}],attrs:{"type":"text","rows":"3"},domProps:{"value":(_vm.newDetails.details)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newDetails, "details", $event.target.value)}}})],1):_vm._e(),_c('div',{staticClass:"flex py-3 mb-6"},[(!_vm.newDetailsForm)?_c('button',{staticClass:"alt-button-green",on:{"click":function($event){return _vm.addNewDetails(1)}}},[_c('span',{staticClass:"typcn typcn-plus icon"}),_c('span',{staticClass:"label"},[_vm._v("Lisa kirjeldus")])]):_vm._e(),(_vm.newDetailsForm && _vm.newDetailsType === 1)?_c('button',{staticClass:"new-button-green mr-2",on:{"click":function($event){return _vm.saveDetails(1)}}},[_c('span',{staticClass:"typcn typcn-tick icon"}),_c('span',{staticClass:"label"},[_vm._v("Salvesta")])]):_vm._e(),(_vm.newDetailsForm && _vm.newDetailsType === 1)?_c('button',{staticClass:"new-button-danger",on:{"click":_vm.closeNewDetailsForm}},[_c('span',{staticClass:"typcn typcn-times icon"}),_c('span',{staticClass:"label"},[_vm._v("Tühista")])]):_vm._e()]),_vm._m(2),_vm._l((_vm.details.filter(function (x) { return x.type === 2; })),function(detail){return _c('detail-template',{key:detail.id,staticClass:"flex flex-row mb-1 w-full",attrs:{"detail":detail},on:{"newDefault":_vm.setNewDefaultTemplate,"editDetails":_vm.editDetailTemplate,"deleteDetails":_vm.deleteDetails}})}),(_vm.newDetailsForm && _vm.newDetailsType === 2)?_c('div',{staticClass:"w-full flex-col flex mt-2"},[_c('h3',[_vm._v("Lisa kirjeldus")]),_c('form-label',{attrs:{"required":true,"title":"Nimetus","valid":!_vm.$v.newDetails.description.$invalid,"error":_vm.$v.newDetails.description.$error,"error-text":!_vm.$v.newDetails.description.maxLength
            ? 'Nimetus võib olla kuni 64 tähemärki pikk'
            : 'Kohustuslik'}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newDetails.description),expression:"newDetails.description"}],staticClass:"my-2",attrs:{"type":"text","placeholder":"Nimetus"},domProps:{"value":(_vm.newDetails.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newDetails, "description", $event.target.value)}}}),_c('form-label',{attrs:{"required":true,"title":"Nimetus","valid":!_vm.$v.newDetails.details.$invalid,"error":_vm.$v.newDetails.details.$error,"error-text":!_vm.$v.newDetails.details.maxLength
            ? 'Sisu võib olla kuni 1024 tähemärki pikk'
            : 'Kohustuslik'}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newDetails.details),expression:"newDetails.details"}],staticClass:"mt-2",attrs:{"type":"text","placeholder":"Sisu","rows":"3"},domProps:{"value":(_vm.newDetails.details)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newDetails, "details", $event.target.value)}}})],1):_vm._e(),_c('div',{staticClass:"flex py-3"},[(!_vm.newDetailsForm)?_c('button',{staticClass:"alt-button-green",on:{"click":function($event){return _vm.addNewDetails(2)}}},[_c('span',{staticClass:"typcn typcn-plus icon"}),_c('span',{staticClass:"label"},[_vm._v("Lisa kirjeldus")])]):_vm._e(),(_vm.newDetailsForm && _vm.newDetailsType === 2)?_c('button',{staticClass:"new-button-green mr-2",on:{"click":function($event){return _vm.saveDetails(2)}}},[_c('span',{staticClass:"typcn typcn-tick icon"}),_c('span',{staticClass:"label"},[_vm._v("Salvesta")])]):_vm._e(),(_vm.newDetailsForm && _vm.newDetailsType === 2)?_c('button',{staticClass:"new-button-danger",on:{"click":_vm.closeNewDetailsForm}},[_c('span',{staticClass:"typcn typcn-times icon"}),_c('span',{staticClass:"label"},[_vm._v("Tühista")])]):_vm._e()])],2):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"flex break-words items-center mb-4"},[_c('span',{staticClass:"typcn typcn-info-large border-green border-2 rounded-full text-xl p-1 leading-none text-green mr-2"}),_vm._v("Lisa siia tekst, mida soovid tihti arvete ja pakkumiste kirjelduses kasutada.")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex w-full items-center mb-4"},[_c('div',{staticClass:"flex w-11/12 mobile:w-full"},[_c('h3',[_vm._v("Pakkumised")])]),_c('div',{staticClass:"flex w-1/12 justify-center mobile:w-6/12 mobile:hidden"},[_c('span',{staticClass:"flex"},[_vm._v("Vaikimisi valik?")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex w-full items-center mb-4"},[_c('div',{staticClass:"flex w-11/12 mobile:w-6/12"},[_c('h3',[_vm._v("Arved")])]),_c('div',{staticClass:"flex w-1/12 justify-center mobile:w-6/12"},[_c('span',{staticClass:"flex"},[_vm._v("Vaikimisi valik?")])])])}]

export { render, staticRenderFns }