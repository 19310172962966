<template>
  <div class="flex flex-col bg-white rounded-gwdhalf w-full">
    <span class="flex break-words mb-6 items-center"
      ><span
        class="typcn typcn-info-large border-green border-2 rounded-full text-xl p-1 leading-none text-green mr-2"
      ></span
      >Koosta siin meilid, mida soovid tihti arvete ja pakkumiste saatmisel
      kasutada.</span
    >
    <div class="flex flex-col w-full" v-if="emailsLoaded">
      <div class="flex w-full items-center flex-row">
        <div class="flex w-11/12 mobile:w-6/12">
          <h3>Pakkumiste meili mallid</h3>
        </div>
        <div class="flex w-1/12 justify-center mobile:hidden">
          <span>Vaikimisi valik?</span>
        </div>
      </div>
      <email-template
        v-for="email in emails.filter(x => x.type === 1)"
        :key="email.id"
        :email="email"
        @newDefault="setNewDefaultTemplate"
        @editEmails="editEmailsTemplate"
        @deleteEmail="deleteEmail"
        class="flex flex-row w-full"
      ></email-template>
      <div
        v-if="newEmailForm && newEmailType === 1"
        class="w-full flex-col flex mt-3"
      >
        <h3>Lisa meil</h3>
        <form-label
          :required="true"
          :valid="!$v.newEmail.description.$invalid"
          :error="$v.newEmail.description.$error"
          :error-text="
            !$v.newEmail.description.maxLength
              ? 'Nimetus võib olla kuni 64 tähemärki pikk'
              : 'Kohustuslik'
          "
          title="Malli nimetus"
        ></form-label>
        <input
          class="mb-2 add-input"
          type="text"
          v-model="newEmail.description"
          :class="$v.newEmail.description.$error ? 'shadow-dangeroutline' : ''"
        />
        <form-label
          :required="true"
          :valid="!$v.newEmail.title.$invalid"
          :error="$v.newEmail.title.$error"
          :error-text="
            !$v.newEmail.title.maxLength
              ? 'Pealkiri võib olla kuni 128 tähemärki pikk'
              : 'Kohustuslik'
          "
          title="Meili pealkiri"
        ></form-label>
        <input
          class="mb-2 add-input"
          type="text"
          v-model="newEmail.title"
          :class="$v.newEmail.title.$error ? 'shadow-dangeroutline' : ''"
        />
        <form-label
          :valid="!$v.newEmail.header.$invalid"
          :error="$v.newEmail.header.$error"
          error-text="Sisu pikkus võib olla kuni 1024 tähemärki"
          title="Sisu"
        ></form-label>
        <textarea
          class="mb-2 add-input"
          type="text"
          :class="$v.newEmail.header.$error ? 'shadow-dangeroutline' : ''"
          v-model="newEmail.header"
          rows="3"
        />
        <form-label
          :valid="!$v.newEmail.footer.$invalid"
          :error="$v.newEmail.footer.$error"
          error-text="Jalutsi pikkus võib olla kuni 1024 tähemärki"
          title="Jaluts"
        ></form-label>
        <textarea
          class="mb-2 add-input"
          :class="$v.newEmail.footer.$error ? 'shadow-dangeroutline' : ''"
          type="text"
          v-model="newEmail.footer"
          rows="3"
        />
      </div>
      <div class="flex py-3 mb-6">
        <button
          v-if="!newEmailForm"
          @click="addNewDetails(1)"
          class="alt-button-green"
        >
          <span class="typcn typcn-plus icon"></span>
          <span class="label">Lisa mall</span>
        </button>
        <button
          v-if="newEmailForm && newEmailType === 1"
          @click="saveEmail(1)"
          class="new-button-green mr-2"
        >
          <span class="typcn typcn-tick bg-green icon"></span>
          <span class="label">Salvesta</span>
        </button>
        <button
          @click="closeNewDetailsForm"
          v-if="newEmailForm && newEmailType === 1"
          class="new-button-danger"
        >
          <span class="typcn typcn-times icon"></span>
          <span class="label">Tühista</span>
        </button>
      </div>

      <div class="flex w-full items-center">
        <div class="flex w-11/12 mobile:w-6/12">
          <h3>Arvete meili mallid</h3>
        </div>
        <div class="flex w-1/12 justify-center mobile:hidden">
          <span>Vaikimisi valik?</span>
        </div>
      </div>
      <email-template
        v-for="email in emails.filter(x => x.type === 2)"
        :key="email.id"
        :email="email"
        @newDefault="setNewDefaultTemplate"
        @editEmails="editEmailsTemplate"
        @deleteEmail="deleteEmail"
        class="flex flex-row w-full"
      ></email-template>
      <div
        v-if="newEmailForm && newEmailType === 2"
        class="w-full flex-col flex mt-3"
      >
        <h3>Lisa meil</h3>
        <form-label
          :required="true"
          :valid="!$v.newEmail.description.$invalid"
          :error="$v.newEmail.description.$error"
          :error-text="
            !$v.newEmail.title.maxLength
              ? 'Nimetus võib olla kuni 64 tähemärki pikk'
              : 'Kohustuslik'
          "
          title="Malli nimetus"
        ></form-label>
        <input
          class="mb-2 add-input"
          type="text"
          v-model="newEmail.description"
          :class="$v.newEmail.description.$error ? 'shadow-dangeroutline' : ''"
        />
        <form-label
          :required="true"
          :valid="!$v.newEmail.title.$invalid"
          :error="$v.newEmail.title.$error"
          :error-text="
            !$v.newEmail.title.maxLength
              ? 'Pealkiri võib olla kuni 128 tähemärki pikk'
              : 'Kohustuslik'
          "
          title="Meili pealkiri"
        ></form-label>
        <input
          class="mb-2 add-input"
          type="text"
          v-model="newEmail.title"
          :class="$v.newEmail.title.$error ? 'shadow-dangeroutline' : ''"
        />
        <form-label
          :valid="!$v.newEmail.header.$invalid"
          :error="$v.newEmail.header.$error"
          error-text="Sisu pikkus võib olla kuni 1024 tähemärki"
          title="Sisu"
        ></form-label>
        <textarea
          class="mb-2 add-input"
          type="text"
          :class="$v.newEmail.header.$error ? 'shadow-dangeroutline' : ''"
          v-model="newEmail.header"
          rows="3"
        />
        <form-label
          :valid="!$v.newEmail.footer.$invalid"
          :error="$v.newEmail.footer.$error"
          error-text="Jalutsi pikkus võib olla kuni 1024 tähemärki"
          title="Jaluts"
        ></form-label>
        <textarea
          class="mb-2 add-input"
          :class="$v.newEmail.footer.$error ? 'shadow-dangeroutline' : ''"
          type="text"
          v-model="newEmail.footer"
          rows="3"
        />
      </div>
      <div class="flex py-3 mb-2">
        <button
          v-if="!newEmailForm"
          @click="addNewDetails(2)"
          class="alt-button-green"
        >
          <span class="typcn typcn-plus icon"></span>
          <span class="label">Lisa mall</span>
        </button>
        <button
          v-if="newEmailForm && newEmailType === 2"
          @click="saveEmail(2)"
          class="new-button-green mr-2"
        >
          <span class="typcn typcn-tick icon"></span>
          <span class="label">Salvesta</span>
        </button>
        <button
          @click="closeNewDetailsForm"
          v-if="newEmailForm && newEmailType === 2"
          class="new-button-danger"
        >
          <span class="typcn typcn-times icon"></span>
          <span class="label">Tühista</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import EmailTemplate from "@/components/company/templates/EmailTemplate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "EmailTemplates",
  components: { EmailTemplate },
  data() {
    return {
      emailsLoaded: false,
      emails: [],
      newEmailForm: false,
      newEmailType: 1,
      newEmail: {
        description: "",
        title: "",
        header: "",
        footer: ""
      }
    };
  },
  mixins: [RequestHandler],
  methods: {
    addNewDetails(type) {
      this.newEmailForm = true;
      this.newEmailType = type;
    },
    loadEmailTemplates() {
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/emails/",
        "get",
        true
      ).then(res => {
        this.emails = res.data;
        this.emailsLoaded = true;
      });
    },
    closeNewDetailsForm() {
      this.newEmail = {
        description: "",
        title: "",
        header: "",
        footer: ""
      };
      this.newEmailForm = false;
    },
    editEmailsTemplate(e) {
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/emails/edit/",
        "patch",
        true,
        e
      ).then(res => {
        if (res) {
          this.emailsLoaded = false;
          this.loadEmailTemplates();
        }
      });
    },
    setNewDefaultTemplate(e, set) {
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/emails/edit/",
        "put",
        true,
        { id: e.id, default: set }
      ).then(res => {
        if (res) {
          this.emailsLoaded = false;
          this.loadEmailTemplates();
        }
      });
    },
    saveEmail(type) {
      this.newEmail["type"] = type;
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/emails/new/",
        "post",
        true,
        this.newEmail
      ).then(res => {
        if (res) {
          this.newEmailForm = false;
          this.newEmail = {
            description: "",
            details: "",
            type: 1
          };
          this.emailsLoaded = false;
          this.loadEmailTemplates();
        }
      });
    },
    deleteEmail(e) {
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/emails/" +
          e.id +
          "/delete/",
        "delete",
        true
      ).then(res => {
        if (res) {
          this.emailsLoaded = false;
          this.loadEmailTemplates();
        }
      });
    }
  },
  mounted() {
    this.loadEmailTemplates();
  },
  validations: {
    newEmail: {
      description: {
        required,
        maxLength: maxLength(64)
      },
      title: {
        required,
        maxLength: maxLength(128)
      },
      header: {
        maxLength: maxLength(1024)
      },
      footer: {
        maxLength: maxLength(1024)
      }
    }
  },
  watch: {
    "newEmail.description": {
      handler(val) {
        if (val.length > 64) {
          this.$v.$touch();
        }
      }
    },
    "newEmail.title": {
      handler(val) {
        if (val.length > 128) {
          this.$v.$touch();
        }
      }
    },
    "newEmail.header": {
      handler(val) {
        if (val.length > 1024) {
          this.$v.$touch();
        }
      }
    },
    "newEmail.footer": {
      handler(val) {
        if (val.length > 1024) {
          this.$v.$touch();
        }
      }
    }
  }
};
</script>

<style lang="scss">
.add-input {
  &::placeholder {
    color: #455a64;
    opacity: 0.8;
  }
}
</style>
